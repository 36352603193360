<template>
  <div
    v-if="inboundInv && branch_id != '71'"
    class="text-muted">
    <i
      class="fa fa-info-circle"
      title="Inventory on order that is not yet availble in warehouse but is on the way!"/>
    <span>
      Inbound: {{ numeral(inboundInv.qty).format('0,0') }} {{ inboundInv.uom }}
    </span>
  </div>
</template>

<script>
  import { inboundAvail } from '../../../../../shared/availability';
  export default{
    props: ['product', 'branch_id', 'activeUom'],
    computed:{
      inboundInv(){return inboundAvail(this.product, this.branch_id, this.activeUom)}
    }
  }
</script>