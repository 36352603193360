<template>
  <div class="container-fluid animate__animated animate__fadeIn animate__faster">
    <div class="row mt-2">
      <!-- Header -->
      <div
        class="col-md-12 bg-black text-center"
        style="padding: 5rem;"
        :style="casionBgStyle">
        <div>
          <img
            :src="logoGold"
            class="img-fluid"
            style="max-width: 500px;">
        </div>
      </div>
      <!-- Header Contents -->
      <div class="col-md-12 text-center my-4">
        <h2 class="poplar">You're Invited</h2>
      </div>
      <div class="col-md-4">
        <p>The 2025 Lancaster Buying Show will allow you to meet face-to-face with manufacturers to see new and exciting products hitting the market. Get inspired by discussing ideas and best business practices with other retailers during the seminars, show and events. Lancaster’s Buying Show will be offering tremendous deals to give you a competitive edge to grow your business in the coming year.</p>
      </div>
      <div class="col-md-4">
        <video
          :src="video"
          :poster="videoPoster"
          controls
          controlsList="nodownload"/>
      </div>
      <div class="col-md-4">
        <h4>Quick Links</h4>
        <ul
          class="no-dot"
          style="line-height: 33px;">
          <li>
            <a
              :href="registrationLink"
              target="_blank">
              Register Today
            </a>
          </li>
          <li>
            <a
              :href="hotelReservationLink"
              target="_blank">
              Host Hotel Reservations
            </a>
          </li>
          <li>
            <a
              :href="usFloorPlan"
              target="_blank">
              Exhibitor Listing & Floor Plan
            </a>
          </li>
          <!-- <li>
            <a
              :href="canada ? cadShowBook : usShowBook"
              target="_blank">
              Show Book
            </a>
          </li> -->
          <!-- <li v-if="vShowAllowed">
            <router-link to="/virtual_show">
              ENTER VIRTUAL SHOW
            </router-link>
          </li> -->
        </ul>
      </div>
    </div>
    <!-- Event Schedule -->
    <div class="row">
      <div class="bg-gray mt-5">
        <div class="row offset">
          <div class="col-md-4 d-flex align-items-center justify-content-center">
            <img
              :src="eventSchedule"
              class="img-fluid">
          </div>
          <div class="col-md-8">
            <div class="row">
              <div class="col-md-12 py-3">
                <h3 style="color: #122c3e;">JANUARY 31 - FEBRUARY 1 • ORLANDO, FL</h3>
              </div>
              <div class="col-md-6">
                <strong style="color: #dc2537;">FRIDAY, JANUARY 31</strong>
                <ul class="event-times">
                  <li>9:30am - 11:30am • High Roller Seminar</li>
                  <li>9:30am - 1:00pm • Customer Pre-Packet Pick-Up</li>
                  <li>
                    <strong>1:00pm - 7:00pm • Lancaster Buying Show</strong>
                  </li>
                  <li>1:00pm - 4:00pm • Early Bird Specials</li>
                  <li>5:00pm - 7:00pm • Show Floor Reception</li>
                </ul>
              </div>
              <div class="col-md-6">
                <strong style="color: #dc2537;">SATURDAY, FEBRUARY 1</strong>
                <ul class="event-times">
                  <li>7:30am - 9:00am • Breakfast</li>
                  <li>
                    <strong>9:00am - 4:00pm • Lancaster Buying Show</strong>
                  </li>
                  <li>12:00pm - 1:30pm • Lunch</li>
                  <li>7:00pm - Until • Dinner & Casino</li>
                  <li>9:30pm - Until • Prize Drawings</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Buying Show Deals -->
    <div
      class="row"
      style="background: radial-gradient(closest-side, #52134a, #320f2d); border-bottom: 1px solid white;">
      <div class="col-md-12 text-center my-5 text-light">
        <h3 style="font-weight: 500;">BUYING SHOW DEALS</h3>
        <h3 style="font-weight: 500;">EXTENDED PAYMENT TERMS</h3>
        <p
          style="max-width: 40em; margin: 0 auto;"
          class="py-3">
          <!-- <span v-if="canada">Not only will you receive the deepest discounts of the year by attending the show. Customers who order more than $750 will also receive net 60 day payment terms.</span> -->
          <span>Not only will you receive the deepest discounts of the year by attending the show, you will also receive extended payment terms of Net 60 Days for show attendees, giving you the ability to stock up on key items for the spring.</span>
        </p>
        <!-- Dates -->
        <div>
          <strong class="d-block">Preorder Dates: 1/20/2025 - 1/31/2025</strong>
          <strong class="d-block">2024 Lancaster Buying Show Dates: 1/31/2025 - 2/1/2025</strong>
        </div>
      </div>
      <!-- Circles -->
      <div
        class="container">
        <div class="row mb-5">
          <div class="col text-center">
            <a href="javascript: void(0)">
              <img
                :src="circle1"
                class="img-fluid circle p-5"> 
            </a>
          </div>
          <div class="col text-center">
            <a href="javascript: void(0)">
              <img
                :src="circle2"
                class="img-fluid circle p-5">
            </a>
          </div>
          <div class="col text-center">
            <a href="javascript: void(0)">
              <img
                :src="circle3"
                class="img-fluid circle p-5">
            </a>
          </div>
        </div>
      </div>
    </div>
    <!-- Cash Spiffs -->
    <div
      class="row"
      style="background: #08090b;"
      :style="casionBgStyle">
      <div
        class="col-md-12 py-5 text-center text-light">
        <div>
          <h3>YOU’RE NOT GOING TO WANT TO MISS OUT...</h3>
        </div>
        <img
          :src="cards"
          class="img-fluid">
        <br>
        <img
          :src="cashSpiffs"
          class="img-fluid">
        <div
          style="max-width: 620px; margin: 0 auto;">
          <div>are offered by many of our exhibitors as an incentive to attend the show and to focus your buying on particular products, however you must attend the 2025 event to earn them.</div>
          <div>*Canadian Exchange Rates Apply</div>
        </div>
      </div>
    </div>
    <!-- Reimbursement -->
    <div class="row py-5">
      <div class="col-md-6 p-5 position-relative">
        <img
          :src="reimbursement"
          class="img-fluid reimbursement p-5">
      </div>
      <div class="col-md-3">
        <img
          :src="airReimbursement"
          class="img-fluid">
      </div>
      <div class="col-md-3">
        <img
          :src="hotelReimbursement"
          class="img-fluid">
      </div>
    </div>
    <!-- Image Slider -->
    <div class="row my-5 d-none d-sm-block">
      <div id="carouselId" class="carousel slide" data-bs-ride="carousel">
        <div class="carousel-inner" role="listbox">
          <div
            v-for="(s,i) in slides"
            class="carousel-item"
            :class="{active: i == 0}">
            <div class="row">
              <div
                v-for="img in s"
                :key="img"
                class="col">
                <img
                  :src="img"
                  class="img-fluid">
              </div>
            </div>
          </div>
        </div>
        <button
          class="carousel-control-prev"
          type="button"
          data-bs-target="#carouselId"
          data-bs-slide="prev">
          <span class="carousel-control-prev-icon" aria-hidden="true"></span>
          <span class="visually-hidden">Previous</span>
        </button>
        <button
          class="carousel-control-next"
          type="button"
          data-bs-target="#carouselId"
          data-bs-slide="next">
          <span class="carousel-control-next-icon" aria-hidden="true"></span>
          <span class="visually-hidden">Next</span>
        </button>
      </div>
      <div class="col-md-12 text-center mt-3 d-none d-lg-block">
        <strong>PICTURES FROM THE 2024 FL BUYING SHOW</strong>
      </div>
    </div>
    <!-- Prize Banner -->
    <div class="row">
      <div class="col-md-6 p-0 text-center">
        <img
          :src="canada ? prizes1Cad : prizes1"
          class="img-fluid p-0">
      </div>
      <div class="col-md-6 p-0 text-center">
        <img
          :src="canada ? prizes2Cad : prizes2"
          class="img-fluid p-0">
      </div>
    </div>
    <!-- Seminars   -->
    <div class="row my-5">
      <div class="col-md-6 text-center align-content-center">
        <img
          :src="diceRoll"
          class="img-fluid"
          style="filter: drop-shadow(2px 4px 6px black);">
      </div>
      <div class="col-md-6">
        <img
          :src="highRoller"
          class="img-fluid mt-5">
        <div class="my-3">
          <h4>FRIDAY, JANUARY 31 • 9:30am - 11:30am</h4>
        </div>
        <div class="mt-3">
          <h5>Win Big by attending the “High Roller Seminar”!</h5>
          <span>Be the first to see NEW & INNOVATIVE products presented by some of Lancaster’s VIP Suppliers. Learn from the experts at this complimentary seminar to see if these products are a fit for your market and receive exclusive jackpot pricing just by attending. Sign-up today to gain access to the High Roller Seminar before the Buying Show opens.</span>
        </div>
      </div>
    </div>
    <!-- Join US -->
    <div
      class="row p-5"
      style="background: radial-gradient(closest-side, #142c50, #0e1c29);">
      <div class="col-md-12 text-center">
        <img
          :src="logoGold"
          class="img-fluid"
          style="max-width: 500px;">
        <h2
          class="mt-5"
          style="color: #ebeed6;">
          DINNER & ENTERTAINMENT
        </h2>
      </div>
      <div class="col-md-12 text-center">
        <div
          class="text-light"
          style="max-width: 550px; margin: 0 auto;">
          <strong>Try your luck at earning additional prize drawing tickets at the Lancaster Casino or just lounge the night away at the Lancaster Dueling Piano Bar, located in the Rotunda outside the Regency Ballroom.</strong>
        </div>
      </div>
      <!-- Circle Pictures -->
      <div class="row mt-5">
        <div class="col-md-4 text-center">
          <img
            :src="casinoCircle1"
            class="img-fluid">
          <ul class="circle-text text-light">
            <li>
              <img
                :src="club"
                class="img-fluid suit">
              DUELING PIANOS
            </li>
            <li>
              <img
                :src="heart"
                class="img-fluid suit">
              BLACKJACK
            </li>
            <li>
              <img
                :src="diamond"
                class="img-fluid suit">
              ROULETTE
            </li>
          </ul>
        </div>
        <div class="col-md-4 text-center">
          <img
            :src="casinoCircle2"
            class="img-fluid">
          <ul class="circle-text text-light">
            <li>
              <img
                :src="spade"
                class="img-fluid suit">
              CRAPS TABLE
            </li>
            <li>
              <img
                :src="club"
                class="img-fluid suit">
              TEXAS HOLD ‘EM
            </li>
            <li>
              <img
                :src="heart"
                class="img-fluid suit">
              POKER TABLES
            </li>
          </ul>
        </div>
        <div class="col-md-4 text-center">
          <img
            :src="casinoCircle3"
            class="img-fluid">
          <ul class="circle-text text-light">
            <li>
              <img
                :src="diamond"
                class="img-fluid suit">
              CARICATURE ARTIST
            </li>
            <li>
              <img
                :src="spade"
                class="img-fluid suit">
              LIVE MUSIC
            </li>
            <li>
              <img
                :src="club"
                class="img-fluid suit">
              PRIZE GIVEAWAYS
            </li>
          </ul>
        </div>
      </div>
    </div>
    <!-- Host Hotel -->
    <div class="row d-flex align-items-end p-5">
      <div class="col-md-12 text-center">
        <div>
          <img
            :src="hostHotel"
            class="img-fluid"
            style="max-width: 250px;">
        </div>
        <a
          :href="hotelReservationLink"
          target="_blank"
          class="btn btn-outline-dark my-3">
          HOTEL RESERVATIONS
        </a> 
      </div>
      <!-- Hotel Details -->
      <!-- Hotel Images -->
      <div class="col-md-4 text-center">
        <div>
          <strong>LOCATION</strong>
          <div>
            <ul class="no-dot">
              <li>
                Hyatt Regency
                <br>
                <a
                  href="https://www.google.com/maps/search/?api=1&query=9801+International+Drive%2C+Orlando%2C+Florida%2C+United+States%2C+32819"
                  target="_blank">
                  9801 International Drive Orlando, FL 32819
                </a>
              </li>
              <li>
                Phone:
                <a href="tel:1-407-284-1234">(407) 284-1234</a>
              </li>
            </ul>
          </div>
        </div>
        <img
          :src="hotel1"
          class="img-fluid hotel-img">
      </div>
      <div class="col-md-4 text-center">
        <div>
          <strong>ROOMS</strong>
          <!-- <div v-if="canada">Single/Double: $268 CAD rate plus taxes and fee. To qualify for this rate, you must make your room reservations by January 10, 2024 (based on availability).*</div> -->
          <div>Single/Double: $235 USD rate plus taxes and fee. To qualify for this rate, you must make your room reservations by January 16th, 2024 (based on availability).*</div>
        </div>
        <img
          :src="hotel2"
          class="img-fluid hotel-img">
      </div>
      <div class="col-md-4 text-center">
        <div>
          <strong>PARKING</strong>
          <!-- <div v-if="canada">Lancaster customers will receive complimentary self-parking for registered overnight guests. Daily self-parking rate for non-registered guests is $49 CAD.</div> -->
          <div>Please note that as a guest, on-site parking, fee: $15/day, plus applicable taxes. If customers are not staying at the Hyatt, parking is $38 per entry.</div>
        </div>
        <img
          :src="hotel3"
          class="img-fluid hotel-img">
      </div>
      <div class="p-5">
        <small>Family-friendly Orlando resort is just minutes from major theme parks including Universal Orlando®, Walt Disney World® and SeaWorld® Orlando. Enjoy shopping, dining and more than 100 unique entertainment options, all within a 2-mile radius of the hotel, located on International Drive.</small>
      </div>
    </div>  
  </div>
</template>

<script>
  import Terms from './Terms.vue'
  export default {
    data(){
      return{
        header: 'https://merit-production.s3.amazonaws.com/lanEstore/events/fl_2025/eStore_Events_Header_Banner.png',
        video: 'https://lancaster-video.s3.amazonaws.com/2025_Lancaster_Buying_Show_Preview.mp4',
        videoPoster: 'https://merit-production.s3.amazonaws.com/lanEstore/events/fl_2025/AdobeStock_113485070.jpeg',
        eventSchedule: 'https://merit-production.s3.amazonaws.com/lanEstore/events/fl_2025/eStore_Events_Event_Schedule.png',
        circle1: 'https://merit-production.s3.amazonaws.com/lanEstore/events/fl_2025/eStore_Events_Show-Book_Specials_coming-soon.png',
        circle2: 'https://merit-production.s3.amazonaws.com/lanEstore/events/fl_2025/eStore_Events_Show-Book_Pre-Show_Ordering_coming-soon.png',
        circle3: 'https://merit-production.s3.amazonaws.com/lanEstore/events/fl_2025/eStore_Events_Show-Book_Early-Bird-Specials_coming-soon.png',
        cards: 'https://merit-production.s3.amazonaws.com/lanEstore/events/fl_2025/AdobeStock_381094597.png',
        reimbursement: 'https://merit-production.s3.amazonaws.com/lanEstore/events/fl_2025/Reimbursement_Qualifications.png',
        airReimbursement: 'https://merit-production.s3.amazonaws.com/lanEstore/events/fl_2025/Airline_Reimbursement_Card.png',
        hotelReimbursement: 'https://merit-production.s3.amazonaws.com/lanEstore/events/fl_2025/Room_Reimbursement_Card.png',
        diceRoll: 'https://merit-production.s3.amazonaws.com/lanEstore/events/fl_2025/AdobeStock_404253393.jpeg',
        highRoller: 'https://merit-production.s3.amazonaws.com/lanEstore/events/fl_2025/High_Roller_Seminar.png',
        logoGold: 'https://merit-production.s3.amazonaws.com/lanEstore/events/fl_2025/2025_Lancaster_Buying_Show_Logo_Gold.png',
        casinoCircle1: 'https://merit-production.s3.amazonaws.com/lanEstore/events/fl_2025/Dinner-Entertainment_Circle_1.png',
        casinoCircle2: 'https://merit-production.s3.amazonaws.com/lanEstore/events/fl_2025/Dinner-Entertainment_Circle_2.png',
        casinoCircle3: 'https://merit-production.s3.amazonaws.com/lanEstore/events/fl_2025/Dinner-Entertainment_Circle_3.png',
        hostHotel: 'https://merit-production.s3.amazonaws.com/lanEstore/events/fl_2025/Host_Hotel.png',
        hotel1: 'https://merit-production.s3.amazonaws.com/lanEstore/events/fl_2025/2025_FL_Show_Hotel_500x500_1.jpg',
        hotel2: 'https://merit-production.s3.amazonaws.com/lanEstore/events/fl_2025/2025_FL_Show_Hotel_500x500_2.jpg',
        hotel3: 'https://merit-production.s3.amazonaws.com/lanEstore/events/fl_2025/2025_FL_Show_Hotel_500x500_3.jpg',
        spade: 'https://merit-production.s3.amazonaws.com/lanEstore/events/fl_2025/Suits_Icons_Spade.png',
        heart: 'https://merit-production.s3.amazonaws.com/lanEstore/events/fl_2025/Suits_Icons_Heart.png',
        diamond: 'https://merit-production.s3.amazonaws.com/lanEstore/events/fl_2025/Suits_Icons_Diamond.png',
        club: 'https://merit-production.s3.amazonaws.com/lanEstore/events/fl_2025/Suits_Icons_Club.png',
        registrationLink: 'https://forms.office.com/Pages/ResponsePage.aspx?id=fPEa6ncEKE2w_7aFUxWX4mIBSbk4s_5IuljxmitwdpJUNEZSWElXSzhLQTJST1hONzVJQ0RSMzAxQiQlQCN0PWcu',
        hotelReservationLink: 'https://www.hyatt.com/en-US/group-booking/MCORO/G-IY57',
        // paralax: 'https://merit-production.s3.amazonaws.com/lanEstore/events/fl_2024/paralax.jpg',
        cashSpiffs: 'https://merit-production.s3.amazonaws.com/lanEstore/events/fl_2025/Cash_Spiff_Text.png',
        usEarlyBirds: 'https://merit-production.s3.amazonaws.com/lanEstore/events/fl_2024/FL2024EarlyBirds.pdf',
        whiteAirplane: 'https://merit-production.s3.amazonaws.com/lanEstore/events/fl_2024/Airplane_White.png',
        whiteHotel: 'https://merit-production.s3.amazonaws.com/lanEstore/events/fl_2024/Hotel_White.png',
        seminarMug: 'https://merit-production.s3.amazonaws.com/lanEstore/events/fl_2024/NHPA_Scott-Wright.jpg',
        orca:'https://merit-production.s3.amazonaws.com/lanEstore/events/fl_2024/SeaWorld_Orca.png',
        rollerCoaster: 'https://merit-production.s3.amazonaws.com/lanEstore/events/fl_2024/SeaWorld_Roller_Coaster.png',
        wallrus: 'https://merit-production.s3.amazonaws.com/lanEstore/events/fl_2024/SeaWorld_Wallrus.png',
        casionBgStyle: {
          backgroundImage: 'url(https://merit-production.s3.amazonaws.com/lanEstore/events/fl_2025/eStore_Events_Background-Design_V2.png)',
          backgroundRepeat: 'no-repeat',
          backgroundSize: 'cover',
          backgroundPosition: 'center',
        },
        wavesStyle: {
          backgroundImage: 'url(https://merit-production.s3.amazonaws.com/lanEstore/events/fl_2024/Waves.png)',
          backgroundRepeat: 'no-repeat',
          backgroundSize: 'cover',
          height: '300px',
          backgroundPosition: 'bottom',
          paddingBottom: '75px'
        },
        prizes1: 'https://merit-production.s3.amazonaws.com/lanEstore/events/fl_2025/Win_Great_Prizes_left.png',
        prizes2: 'https://merit-production.s3.amazonaws.com/lanEstore/events/fl_2025/Win_Great_Prizes_right.png',
        prizes1Cad: 'https://merit-production.s3.amazonaws.com/lanEstore/events/fl_2024/WIN_Prize%20Sponsor_1_CANADA.png',
        prizes2Cad: 'https://merit-production.s3.amazonaws.com/lanEstore/events/fl_2024/WIN_Prize%20Sponsor_2_CANADA.png',
        usFloorPlan: 'https://merit-production.s3.amazonaws.com/lanEstore/events/fl_2025/2025_Lancaster-FL-Show_Floor-Plan_Listing_US.pdf',
        cadFloorPlan: 'https://merit-production.s3.amazonaws.com/lanEstore/events/fl_2024/2024_FL-Buying-Show_Vendor-Listing_Floor-Map_CANADA.pdf',
        usShowBook: 'https://merit-production.s3.amazonaws.com/lanEstore/events/fl_2024/2024-FL-Lancaster-Show-Book_Revisions-Included.pdf',
        usPreShow: 'https://merit-production.s3.amazonaws.com/lanEstore/events/fl_2024/2024-FL-Lancaster-Show-Book-Front-Info_PreShow_Ordering.pdf',
        usEarlyBirds: 'https://merit-production.s3.amazonaws.com/lanEstore/events/fl_2024/2024-Lancaster-Show-Book-EB-Specials-FRONT_SECTION.pdf',
        cadShowBook: 'https://merit-production.s3.amazonaws.com/lanEstore/events/fl_2024/2024%20FL-Show-Book_CANADA.pdf',
        cadEarlyBirds: 'https://merit-production.s3.amazonaws.com/lanEstore/events/fl_2024/2024-Lancaster-Show-Book-EB-Specials-FRONT-SECTION-CA.pdf'
      }
    },
    components:{
      Terms
    },
    computed:{
      sessionCompany(){return this.$store.getters.sessionCompany},
      slides(){
        let slides = []
        for(let i=1; i<=25; i++){
          slides.push(`https://merit-production.s3.amazonaws.com/lanEstore/events/fl_2025/slides/slide${i}.jpg`)
        }
        return _.chunk(slides, 5)
      },
      canada(){return this.sessionCompany == 'Lancaster Canada'},
      vShowAllowed(){return this.$store.getters.vShowAllowed}
    }
  }
</script>

<style scoped>
  video{
    width: 100%;
  }
  .no-dot{
    list-style-type: none;
    padding: 0px;
  }
  ul{
    padding: 0px;
  }
  .poplar{
    color: black;
    font-size:50px;
    font-weight: 900;
  }
  .bg-gray{
    background: #e6e7e8;
  }
  .event-times{
    line-height:40px;
    list-style-type: none;
  }
  /* .circle{
    height: fit-content;
    width: fit-content;
    padding: 30px;
    margin: 0 auto;
    border: 3px solid #f89422;
    border-radius: 50%;
    margin-top: 20px;
  }
  .circle span{
    color: #f89422;
    font-size: 25px;
  } */
  .special{
    font-family: POPLARSTD;
    font-size: 50px !important;
  }
  .circle:hover{
    cursor:pointer;
    transform: scale(1.05);
    transition: 150ms ease-in;
    /* background: #f89422;
    text-shadow: 1px 1px 10px whitesmoke; */
  }
  .black-wrapper{
    background: rgb(18, 44, 61, .8);
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    align-items: center;
    display: flex;
    justify-content: center;
  }
  .slider-img{
    object-fit: cover;
    height: 200px;
    width: 100%;
    min-width: 100px;
  }
  .gray-gradient{
    background: linear-gradient(to bottom, white, #e6e7e8 )
  }
  .circle-img{
    object-fit: cover;
    object-position: center;
    border-radius: 50%;
    padding: 30px;
    overflow: hidden;
  }
  @media(max-width: 1500px){
    .circle-img{
      width: 250px;
      height: 250px;
    }
  }
  @media(min-width: 1501px){
    .circle-img{
      width: 400px;
      height: 400px;
    }
  }
  .hotel-img{
    height: 300px;
    width: 400px;
    object-fit: cover;
    object-position: center;
    padding: 10px;
  }
  .circle-link{
    text-decoration: none;
    transition: filter 150ms ease-in;
  }
  .circle-link:hover{
    filter: drop-shadow(2px 4px 6px black) hue-rotate(45deg);
  }
  .carousel-control-next{
    width: 5%;
    opacity: 1;
  }
  .carousel-control-prev{
    width: 5%;
    opacity: 1;
  }
  .reimbursement{
    position: absolute;
    max-width: 500px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  .circle-text{
    list-style-type: none;
    font-weight: 900;
    font-size: 20px;
    padding: 20px 0px;
  }
  .circle-text li{
    padding: .5rem;
  }
  .suit{
    height: 15px;
    width: 15px;
  }
</style>