<template>
  <div class="row mt-4">
    <div class="col-md-12 position-relative">
      <i
        class="fa fa-times text-danger close-times"
        @click="removeProduct(order)"/>
    </div>
    <div class="col-md-2 text-center">
        <img
          :src="order.product.image_url"
          @error="imgError"
          style="max-width: 75px; max-height: 100px;">
    </div>
    <div class="col-md-5">
      <div>{{order.product.description}}</div>
    </div>
    <div class="col-md-3">
      <table class="table table-sm">
        <thead>
          <th>
            <small class="text-muted">Qty</small>
          </th>
          <th>
            <small class="text-muted">Unit Price</small>
          </th>
          <th>
            <small class="text-muted">Uom</small>
          </th>
        </thead>
        <tbody>
          <tr>
            <td style="width:100px;">
              <input
                type="number"
                class="form-control form-control-sm"
                @change="update"
                @focus="this.$emit('focus')"
                @blur="this.$emit('blur')"
                :min="minQty.quantity"
                :step="minQty.quantity"
                v-model="order.orderData.qty">
            </td>
            <td>{{numeral(pricing(order).unitPrice).format('$0,0.00')}}</td>
            <td>{{order.orderData.uom}}</td>
          </tr>
          <tr>
            <td
              v-if="msg"
              colspan="3"
              class="badge bg-danger animate__animated animate__bounceIn animate__faster">
              <i class="fa fa-exclamation-triangle"/>
              {{msg}}
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <div class="col-md-2">
      <table class="table table-sm">
        <thead>
          <th>
            <small class="text-muted">Ext Price</small>
          </th>
        </thead>
        <tbody>
          <tr>
            <td>{{numeral(pricing(order).extPrice).format('$0,0.00')}}</td>
          </tr>
        </tbody>
      </table>
    </div>
    <hr class="mt-2">
  </div>
</template>

<script>
  import {showDetail} from '../../../../../../shared/showDetail'
  import {minQuantity} from '../../../../../../shared/ProductFunctions'
  import comingSoon from '../../../../../../shared/comingSoon'
  
  export default {
    inject:['env'],
    props: ['order'],
    data(){
      return{
        showUpdate: false,
        msg: null,
        calcState: true
      }
    },
    methods:{
      showUpdateBtn(){
        this.showUpdate = true
      },
      update(){
        if(this.order.orderData.qty % this.minQty.quantity == 0 && this.order.orderData.qty > 0){
          this.$store.dispatch('updateOrderPadProduct', this.order.orderData)
          this.msg = null
          this.calcState = true
        }else if(this.order.orderData.qty == 0 || this.order.orderData.qty == ''){
          this.calcState = false
          this.msg = `Must order in Qty's of ${this.minQty.quantity}`
        }else{
          this.calcState = false
          this.msg = `Must order in Qty's of ${this.minQty.quantity}`
        }
        this.$emit('calcState', this.calcState)
      },
      pricing(orderLine){
        const detail = showDetail(orderLine.product)
        let qtyBreak = null
        if(detail){
          if(detail.qty_break3 && orderLine.orderData.qty >= detail.qty_break3){
            qtyBreak = {
              qty: detail.qty_break3,
              price: detail.qty_break3_price,
              uom: detail.qty_break3_uom
            }
          }else if(detail.qty_break2 && orderLine.orderData.qty >= detail.qty_break2){
            qtyBreak = {
              qty: detail.qty_break2,
              price: detail.qty_break2_price,
              uom: detail.qty_break2_uom
            }
          }else if(detail.qty_break1 && orderLine.orderData.qty >= detail.qty_break1){
            qtyBreak = {
              qty: detail.qty_break1,
              price: detail.qty_break1_price,
              uom: detail.qty_break1_uom
            }
          }
          if(qtyBreak){
            return {
              unitPrice: qtyBreak.price,
              extPrice: this.calcState ? parseFloat(qtyBreak.price) * orderLine.orderData.qty : 0
            }
          }else{
            return {
              unitPrice: detail.show_price,
              extPrice: this.calcState ? detail.show_price * orderLine.orderData.qty : 0
            }
          }
        }
      },
      removeProduct(orderLine){
        const product_id = orderLine.orderData.product_id
        this.$store.dispatch('deleteOrderPadProduct', product_id)
      },
      imgError(e){
        comingSoon(e)
      }
    },
    computed:{
      minQty(){
        return minQuantity(this.order.product, null, this.env)
      }
    }
  }
</script>

<style scoped>
  .close-times{
    position: absolute;
    top: -10px;
    right: 10px;
    cursor: pointer;
  }
</style>