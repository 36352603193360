<template>
  <div v-if="product.prop65_warning && !cadEntity">
    <!-- Default View -->
    <div
      v-if="!expanded"
      class="prop-link"
      @click="toggleExpanded">
      <i class="fa fa-exclamation-triangle text-warning"/>
      <span class="text-muted">
        Warning: Prop 65
      </span>
    </div>
    <!-- Expanded View -->
    <div
      v-else
      class="border border-primary rounded p-2 shadow-sm animate__animated animate__fadeIn animate__fast">
      <i
        class="fa fa-times float-end text-muted prop-close"
        @click="toggleExpanded"/>
      <i class="fa fa-exclamation-triangle text-warning"/>
      <a
        class="text-center"
        href="https://www.P65Warnings.ca.gov"
        target="_blank">
        {{ product.prop65_warning }}
      </a>
    </div>
  </div>
</template>

<script>
  export default{
    props: ['product'],
    data(){
      return{
        expanded: false
      }
    },
    methods:{
      toggleExpanded(){
        this.expanded = !this.expanded
      }
    },
    computed:{
      cadEntity(){
        return this.$store.getters.getEntity.activeShipTo.branch_id == '71' || false
      }
    }
  }
</script>

<style scoped>
  .prop-link{
    cursor: pointer;
  }
  .prop-link:hover{
    font-weight: bolder;
  }
  .prop-close{
    cursor: pointer;
  }
</style>