<template>
  <div class="mx-1 dropdown">
    <!-- DropDown Button -->
    <button
      class="btn btn-primary btn-sm dropdown-toggle"
      :disabled="loading"
      :class="mobile ? 'w-100' : ''"
      data-bs-toggle="dropdown"
      data-bs-auto-close="outside">
      Branches
    </button>
    <!-- DropDown Menu -->
    <div
      class="dropdown-menu p-2 wrapper"
      :class="{'w-100': fullWidthMenu}"
      id="branchFilterDropdown">
      <!-- Mode -->
      <div>
        <small class="text-muted">Filter Mode</small>
        <div class="btn-group w-100">
          <button
            v-if="routeAction != 'clearance'"
            class="btn btn-primary btn-sm"
            :class="{active: mode == 'stock'}"
            id="mode1"
            @click="setMode('stock')">
            Stocked
          </button>
          <button
            class="btn btn-primary btn-sm"
            :class="{active: mode == 'avail'}"
            id="mode2"
            @click="setMode('avail')">
            Availability
          </button>
        </div>
      </div>
      <!-- Branch Selection -->
      <div class="row my-2">
        <div class="col-md-12 d-flex align-items-end justify-content-between">
          <strong class="float-start">Select Branches</strong>
          <div class="float-end">
            <button
              class="btn btn-sm btn-outline-primary"
              @click="clear">
              <i class="fa fa-ban"/>
              Clear
            </button>
          </div>
        </div>
      </div>
      <!-- Content -->
      <!-- Branch Mode Content -->
      <div v-if="branchList && branchSelection">
        <ul class="branch-list">
          <li
            v-for="(b, i) in branchList"
            :key="b">
            <div class="pretty p-default">
              <input
                :id="b + 'checkbox'"
                type="checkbox"
                v-model="branchSelection[i].checked"/>
              <div
                class="state p-primary"
                :style="['S27E', 'S60'].includes(b) ? 'margin: -0.1rem;' : ''">
                  <label
                    :for="b + 'checkbox'">
                    {{ b }}
                  </label>
                  <!-- EJW DC's & Home -->
                  <span
                    v-if="['S27E', 'S60', 'S69'].includes(b)"
                    class="ms-1 badge bg-theme-red"
                    style="padding: 2px 5px; min-width:45px;">
                    <i
                    v-if="homeBranch == b"
                    class="fa fa-home"/>
                    DC
                  </span>
                  <!-- LAN Home Branches -->
                  <span v-else>
                    <i
                      v-if="homeBranch == b"
                      class="fa fa-home text-info ms-1"/>
                  </span>
              </div>
            </div>
          </li>
        </ul>
      </div>
      <!-- Action -->
      <button
        @click="submit" 
        :disabled="!haveValues"
        class="btn btn-primary btn-sm w-100"
        data-bs-dismiss="offcanvas">
        Apply
      </button>
    </div>
  </div>
</template>

<script>
  import hideDropDown from '../../../../../../shared/hideDropdown'
  export default{
    inject: ['env'],
    props: ['loading', 'filters', 'mobile', 'fullWidthMenu'],
    data(){
      return{
        branchSelection: null,
        mode: 'stock'
      }
    },
    mounted(){
      this.branchSelection = this.branchData
      if(this.routeAction == 'clearance') this.mode = 'avail'
    },
    methods:{
      setMode(mode){
        this.mode = mode
      },
      clear(){
        this.branchSelection.forEach(b=>{
          b.checked = false
        })
        this.submit()
      },
      submit(){
        hideDropDown('branchFilterDropdown')
        // Build filter event object
        const data = {
          path: this.$route.path,
          action: 'branchFilter',
          mode: this.mode,
          branches: this.branchSelection ? _.map(_.filter(this.branchSelection, {checked: true}), 'branchID') : []
        }
        // Submit
        this.$store.dispatch('filterProducts', data)
      }
    },
    computed:{
      branchList(){return this.filters.options.branches},
      branchData(){
        if(this.branchList){
          return _.map(this.branchList, (b) => {
            return {branchID: b, checked: false}
          })
        }
      },
      haveValues(){
        if(this.branchSelection) return _.filter(this.branchSelection, {checked: true}).length > 0
        return false
      },
      homeBranch(){return this.$store.getters.homeBranch},
      routeAction(){return this.$router.currentRoute.value.query?.action}
    }
  }
</script>

<style scoped>
  .wrapper{
    width:275px;
    max-height:355px;
    position:relative;
  }
  .branch-list{
    list-style-type: none;
    margin-top: 10px;
    border: 1px solid lightgray;
    max-height: 185px;
    overflow: hidden scroll;
    padding: 5px;
  }
  ul{
    padding-left: 1rem;
  }
</style>