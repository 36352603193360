<template>
  <div
    v-if="product.eclipse_data && activeShipToId == product.eclipse_data.last_ordered_customer_id"
    class="last-ordered">
    <span class="text-muted">
      Last Ordered: {{product.eclipse_data.last_ordered_date}}
    </span>
    <br>
    <span class="text-muted">
      Last Order Qty: {{product.eclipse_data.last_ordered_quantity}} {{product.eclipse_data.uom}} 
    </span>
  </div>
</template>

<script>
  export default {
    props: ['product'],
    computed:{
      activeShipToId(){return this.$store.getters.getEntity.activeShipTo.entity_id}
    }
  }
</script>

<style scoped>
  .last-ordered{
    font-size: 12px;
  }
</style>