<template>
  <div v-if="display">
    <div v-if="(outOfStock || !stockedInHomeBranch) && !shippingCommentApplied">
      <!-- User must agree to shipping terms if item is not stocked in home branch -->
      <div
        class="row alert p-2"
        :class="outOfStock ? 'alert-primary' : 'alert-warning'">
        <!-- Not stocked in home branch -->
        <div
          v-if="!stockedInHomeBranch"
          class="col-md-8">
          <span class="text-dark">
            <span>This item is </span>
            <span style="font-weight: 900"> not stocked </span>
            <span>in your </span>
            <i class="fa fa-home text-info"/>
            <span v-if="env.includes('EJW')"> Home DC. </span>
            <span v-else> Home Branch. </span>
            To have this item ship from another
            <span v-if="env.includes('EJW')"> DC </span>
            <span v-else> branch </span>
            with an added freight charge, please click the button. Note that this item is subject to being cancelled off the order. Please review the current freight policy.
          </span>
        </div>
        <div
          v-else-if="outOfStock"
          class="col-md-8">
          <!-- Out of stock message -->
          <span
            class="text-dark">
            <span>This item is </span>
            <span style="font-weight: 900"> out of stock </span>
            <span>in your </span>
            <i class="fa fa-home text-info"/>
            <span v-if="env.includes('EJW')"> Home DC. </span>
            <span v-else> Home Branch. </span>
            To have this item ship from another
            <span v-if="env.includes('EJW')"> DC </span>
            <span v-else> branch </span>
            with an added freight charge, please click the button. Note that this item is subject to being cancelled off the order. Please review the current freight policy.
          </span>
        </div>
        <div class="col-md-4 text-center d-flex align-items-center justify-content-center">
          <button
            class="btn btn-primary btn-sm pre"
            @click="applyShippingComment">
            <i class="fa fa-truck"/>
            Ship With Added Freight Charge
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    inject: ['env'],
    props:['product', 'shippingCommentApplied'],
    data(){
      return{
        shippingComment: "Ship item from another warehouse with added freight charge"
      }
    },
    methods:{
      applyShippingComment(){
        this.product.comment = this.product.comment ? this.product.comment + ' , ' + this.shippingComment : this.shippingComment
        this.$emit('updateProduct')
      },
    },
    computed:{
      homeBranch(){return this.$store.getters.homeBranch},
      stockedInHomeBranch(){
        if(this.product.product_data.eclipse_data){
          // Check to see if actualy inventory qty is available in home branch
          const branchRecord = _.find(this.product.product_data.eclipse_data.branches, {id: this.homeBranch})
          if(branchRecord && parseInt(branchRecord.avail) > 0){
            return true
          }else{
            // Check to see if item is flagged as a stocked item in homebranch
            const branchList = this.product.product_data.stock_branches ? _.compact(this.product.product_data.stock_branches.split(',')) : []
            return _.includes(branchList, this.homeBranch)
          }
        }
      },
      outOfStock(){
        if(this.stockedInHomeBranch){
          const branchRecord = _.find(this.product.product_data.eclipse_data.branches, {id: this.homeBranch})
          if(branchRecord && parseInt(branchRecord.avail) == 0) return true
        }
        return false
      },
      sessionCompany(){return this.$store.getters.sessionCompany},
      display(){
        if(this.env.includes('EJW')){
          return this.$store.getters.getEntity.activeShipTo.entity.dc ? true : false
        }
        if(this.env == 'LAN'){
          return ['Lancaster US'].includes(this.sessionCompany)
        }
      }
    }
  }
</script>

<style scoped>
  .pre{
    white-space: pre-wrap;
    word-break: keep-all;
  }
</style>