// Is it stocked at this branch?
export const stockedInBranch = (product) => {
  const branchList = product.rel_branches ? _.compact(_.uniq(product.rel_branches.split(','))) : null
  return branchList
}

export const stockFlagInBranch = (product) => {
  const branchList = product.stock_branches ? _.compact(_.uniq(product.stock_branches.split(','))) : null
  return branchList
}

export const invInBranch = (product) => {
  const branchList = product.inv_branches ? _.compact(_.uniq(product.inv_branches.split(','))) : null
  return branchList
}