<template>
  <div>
    <div v-if="showHome">
      <i class="fa fa-home text-info"/>
      {{numeral(homeBranch.quantity).format(homeBranch.quantity >= 10000 ? '0a' : '0,0')}} {{homeBranch.uom}}
    </div>
    <div v-else>
      <table
        class="table table-sm"
        :class="showHome ? 'hide-border' : ''"
        :key="product.product_id">
        <thead>
          <th class="text-center">Branch</th>
          <th class="text-center">Qty</th>
          <th class="text-center">Stocked</th>
        </thead>
        <tbody>
          <tr
            v-for="b in branches"
            :key="b.id">
            <td class="text-center">
              {{b.branch_name}} ({{b.branch_id}})
              <i
                v-if="b.branch_id == home"
                class="fa fa-home text-info"/>
            </td>
            <td class="text-center">
              {{numeral(b.quantity).format(b.quantity >= 10000 ? '0a' : '0,0')}} {{b.uom}}
              <InboundInventory
                :product="product"
                :branch_id="b.branch_id"/>
            </td>
            <td class="text-center">
              <div
                v-if="stockFlagInBranch && stockFlagInBranch.includes(b.branch_id) && b.quantity != 104"
                class="w-100 text-success indicator"
                title="This product is stocked in this branch">
                <i class="fa fa-check-circle"/>
              </div>
              <div
                v-else-if="invInBranch && invInBranch.includes(b.branch_id)"
                class="w-100 text-warning indicator"
                title="This product is not stocked in this branch however we still have inventory available">
                <i class="fa fa-exclamation-circle"/>
              </div>
              <div
                v-else
                class="w-100 text-muted indicator"
                title="This product is not stocked in this branch">
                <i class="fa fa-times-circle"/>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
  import InboundInventory from './InboundInventory.vue';

  export default{
    props: ['product', 'branchAvail', 'stockFlagInBranch', 'invInBranch', 'home', 'showHome'],
    components:{
      InboundInventory
    },
    computed:{
      homeBranch(){
        if(this.showHome){
          return _.find(this.branchAvail, {branch_id: this.home})
        }
      },
      branches(){
        return this.branchAvail
      }
    }
  }
</script>

<style scoped>
  .hide-border{
    border: hidden;
  }
  table{
    font-size:14px;
  }
  .indicator:hover{
    cursor: pointer;
    transform: scale(1.2);
  }
</style>