<template>
  <div class="container-fluid">
    <div
      v-if="dashboard"
      class="mt-2 animate__animated animate__fadeIn text-light">
      <div class="row text-dark">
        <h2 class="text-center text-light">Today's eStore Stats</h2>
        <!-- Sales $ -->
        <div class="col-md-3 p-3">
          <div
            class="stat-card bg-light rounded shadow">
            <h5>Sales</h5>
            <h1>{{ numeral(dashboard.total_sales).format('$0,0.00') }}</h1>
          </div>
        </div>
        <!-- User Count -->
        <div class="col-md-3 p-3">
          <div
            class="stat-card bg-light rounded shadow">
            <h5>User Count</h5>
            <h1>{{ dashboard.users.length }}</h1>
          </div>
        </div>
        <!-- Sales Order Count -->
        <div class="col-md-3 p-3">
          <div class="stat-card bg-light rounded shadow">
            <h5>Submitted Orders</h5>
            <h1>{{ dashboard.sales.length }}</h1>
          </div>
        </div>
        <!-- Top Sellers -->
        <div class="col-md-3 p-3">
          <div class="stat-card bg-light rounded shadow">
            <h5>
              Top Sellers
              <span
                class="text-muted"
                style="font-size:12px;">
                (Product ID)
              </span>
            </h5>
            <ul v-if="dashboard.top_sellers.length">
              <li
                v-for="t in dashboard.top_sellers"
                :key="t.eclipse_id">
                {{ t.eclipse_id }} ({{ t.quantity }} {{ t.uom }})
              </li>
            </ul>
            <div v-else>
              N/A
            </div>
          </div>
        </div>
        <!-- Top Sales Order -->
        <div class="col-md-3 p-3">
          <div class="stat-card bg-light rounded shadow">
            <h5>Top Sales Order</h5>
            <ul v-if="topSalesOrder.user_id">
              <li
                v-for="(v,k) in topSalesOrder"
                :key="k['id']">
                {{ k }}: {{ k == 'cart_total' ? numeral(v).format('$0,0.00') : v }}
              </li>
            </ul>
            <div v-else>
              N/A
            </div>
          </div>
        </div>
        <!-- Open Carts -->
        <div class="col-md-3 p-3">
          <div class="stat-card bg-light rounded shadow">
            <h5>
              Open Carts
              <span
                class="text-muted"
                style="font-size:12px;">
                (Past 30 days old)
              </span>
            </h5>
            <ul v-if="dashboard.open_carts">
              <li
                v-for="(v,k) in dashboard.open_carts"
                :key="v">
                {{ k }}: {{ k == 'total' ? numeral(v).format('$0,0.00') : v }}
              </li>
            </ul>
            <div v-else>
              N/A
            </div>
          </div>
        </div>
        <!-- Search Stats -->
        <div class="col-md-3 p-3">
          <div class="stat-card bg-light rounded shadow">
            <h5>Search Stats</h5>
            <ul>
              <li>Camera Scans: {{ dashboard.search_stats.camera_scans }}</li>
              <li>Searches: {{ dashboard.search_stats.search_count }}</li>
            </ul>
          </div>
        </div>
        <!-- Top Searches -->
        <div class="col-md-3 p-3">
          <div class="stat-card bg-light rounded shadow">
            <h5>Top Searches</h5>
            <ul>
              <li
                v-for="s in dashboard.search_stats.top_searches"
                :key="s.query">
                "{{ s.query }}" ({{ s.count }})
              </li>
            </ul>
          </div>
        </div>
        <hr>
      </div>
      <!-- User Login Table -->
      <div
        v-if="dashboard.users.length"
        class="row">
        <div class="col-md-12 p-1">
          <div class="stat-card-big">
            <h5>User Login Details</h5>
            <table
              v-if="dashboard.users.length"
              class="table table-light table-striped bg-light shadow">
              <thead>
                <th>id</th>
                <th>username</th>
                <th>created_at</th>
                <th>updated_at</th>
                <th>entity_id</th>
                <th>contact_id</th>
                <th>role</th>
                <th>default_ship_to</th>
              </thead>
              <tbody>
                <tr
                  v-for="u in dashboard.users"
                  :key="u.id">
                  <td>{{ u.id }}</td>
                  <td>{{ u.username }}</td>
                  <td>{{ u.created_at }}</td>
                  <td>{{ u.updated_at }}</td>
                  <td>{{ u.entity_id }}</td>
                  <td>{{ u.contact_id }}</td>
                  <td>{{ u.role }}</td>
                  <td>{{ u.default_ship_to }}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
    <!-- Spinner -->
    <div
      v-if="loading"  
      class="row mt-5">
      <div class="col-md-12 text-center">
        <Spinner color="white"/>
      </div>
    </div>
  </div>
</template>

<script>
  import axios from 'axios'
  import Spinner from '../../global/Spinner.vue'
  export default{
    data(){
      return{
        loading: true,
        dashboard: null
      }
    },
    components:{
      Spinner
    },
    mounted(){
      axios.get('/api/v1/private/admin/dashboard/get_data').then(res=>{
        this.dashboard = res.data.dashboard
        this.loading = false
      })
    },
    computed:{
      topSalesOrder(){
        return _.pick(this.dashboard.top_sales_order, ['user_id', 'order_number', 'cart_total', 'vshow_cart'])
      }
    }
  }
</script>

<style scoped>
  .stat-card{
    height: 200px;
    border-radius: 10px;
    padding:20px;
  }
  .stat-card-big{
    height: 600px;
    border-radius: 10px;
    padding:20px;
  }
</style>